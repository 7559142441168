import React, { useEffect, useState } from 'react';
import firebase from '../firebaseConfig';
import 'firebase/auth';
import { PrimaryButton, TextField } from '@fluentui/react';
import { Link, useNavigate } from "react-router-dom";
import { ChevronLeftIcon, XMarkIcon,PencilSquareIcon,CheckCircleIcon } from "@heroicons/react/20/solid";
const PhoneVerification = (props:any) => {
  const [phoneNumber, setPhoneNumber] = useState();
  const [otp, setOtp] = useState('');
  const [confirmationResult, setConfirmationResult] = useState<any>(null);
  const [verified,setVerified] = useState(false);
  useEffect(()=>{
    setPhoneNumber(props?.phoneNumber)
    setVerified(props?.phoneNumber != null? true:false)
  },[props?.phoneNumber ])
  const navigate = useNavigate();
  useEffect(()=>{
    handleSendOtp();
  },[])
  const handleSendOtp = async () => {
    try {
      
      const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
      // const phNumber = `+91${phoneNumber}`
      const phNumber = process.env.REACT_APP_phoneNumber+""
      const result = await firebase.auth().signInWithPhoneNumber(phNumber, appVerifier);
      setConfirmationResult(result);
      // setVerified(true);
      console.log('OTP sent to phone number');
    } catch (error) {
      console.log(error);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const credential = firebase.auth.PhoneAuthProvider.credential(confirmationResult.verificationId, otp);
      const userCredential = await firebase.auth().signInWithCredential(credential);
      const user = userCredential.user;
      console.log('Successfully verified the OTP');
      // props.setPhoneNumber(phoneNumber);
      setVerified(true);
      navigate("/dashboard");
      alert("Successfully verified the OTP")
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='items-center justify-center content-center'>
        
        {/* <div className='flex items-center justify-center'>
      <TextField
        label="Phone Number"
        className='w-48'
        value={phoneNumber}
        onChange={(e: any) => setPhoneNumber(e.target.value)}
        // disabled={verified}
      />
      {verified && <CheckCircleIcon className="block h-6 w-6 mt-6 text-green-700" aria-hidden="true"/>}
      {/* <PencilSquareIcon className="block h-6 w-6 mt-6" aria-hidden="true" onClick={()=>setVerified(false)}/> */}
      {/* </div> */} 
      <div className='flex flex-col items-center justify-center place-content-center'>
      {/* <PrimaryButton
      //  disabled={verified}
        className={`mt-2 ${verified ? 'rounded bg-gray-500 text-white' : 'rounded'}`} 
        href="#" color="primary" onClick={handleSendOtp}>
       {verified ? "Verified": "Resend"}
      </PrimaryButton> */}
{!verified &&<>
      <div id="recaptcha-container"></div>

      <TextField
        label="Enter OTP"
        className='w-48'
        value={otp}
        onChange={(e: any) => setOtp(e.target.value)}
      />
      <PrimaryButton className='mt-2 rounded' href="#" color="primary" onClick={handleVerifyOtp}>
        Verify OTP
      </PrimaryButton></>
      }
      </div>
    </div>
  );
};

export default PhoneVerification;
