import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Login from "./login/Login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DashBoard from "./Dashboard/DashBoard";
import UserTable from "./Dashboard/UserTable";
import PhoneVerification from "./login/PhoneVerification";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" Component={Login} />
        <Route path="/dashboard" Component={DashBoard} />
        <Route path="/phoneVerification" Component={PhoneVerification} />
        
      </Routes>
    </Router>
  );
}

export default App;
