import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
// import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// export const options = {
//   responsive: true,
//   plugins: {
//     legend: {
//       position: 'top' as const,
//     },
//     title: {
//       display: true,
//       // text: `Today Orders - ${new Date().toLocaleDateString()}`,
//       text: `Today Orders - ${new Date().toLocaleDateString(undefined, {
//         day: 'numeric',
//         month: 'long',
//         year: 'numeric',
//       })}`
//     },
    
//   },
// };
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: `Last 7 Days Data - ${generateDateRangeLabel()}`,
    },
  },
};
function generateDateRangeLabel() {
  const endDate = new Date(); // Use the current date as the end date
  const startDate = new Date(endDate);
  startDate.setDate(startDate.getDate() - 6); // Subtract 6 days to get the start date

  const startLabel = startDate.toLocaleDateString(undefined, {
    day: 'numeric',
  });
  const endLabel = endDate.toLocaleDateString(undefined, {
    day: 'numeric',
  });

  return `${startLabel} - ${endLabel}`;
}




// const labels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];


export default function LineChart(props:any) {
  //  const data = {
  //   labels,
  //   datasets: [
  //     {
  //       label: 'price',
  //       // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
  //       data:props.hourlyOrderPrice,
  //       borderColor: 'rgb(255, 99, 132)',
  //       backgroundColor: 'rgba(255, 99, 132, 0.5)',
  //     },
  //     {
  //       label: 'orders ',
  //       // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
  //       data:props.hourlyOrderCount,
  //       borderColor: 'rgb(53, 162, 235)',
  //       backgroundColor: 'rgba(53, 162, 235, 0.5)',
  //     },
  //   ],
  // };
  console.log(props?.data,"props?.data123")
  return (
    <Line 
    options={options} 
    data={props?.data} 
    style={{ width:'800px',height:"600px"}}
    />
  
  )
}
