import axios from "axios";
import React, { useEffect, useState } from "react";
import LineChart from "../charts/LineChart";
import Barchart from "../charts/BarChart";
import {
  ContextualMenu,
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  FontWeights,
  IColumn,
  IDragOptions,
  Label,
  Link,
  Modal,
  SelectionMode,
  TextField,
  getTheme,
  mergeStyleSets,
  Text,
} from "@fluentui/react";
import { ChevronLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useId, useBoolean } from "@fluentui/react-hooks";
let monthlyOrdersCount: any = [];
let monthlyOrdersPrice: any = [];
let weeklyOrdersCount: any = [];
let weeklyOrdersPrice: any = [];
let allItems:any;
const dragOptions: IDragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
  dragHandleSelector: ".ms-Modal-scrollableContent > div:first-child",
};
function Orders(props: any) {
  // Frontend: Function to get all orders with authentication token
  const [items, setItems] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState(Math.ceil(items.length / 10));
  const [userMail,setUserMail] = useState<any>("");
  const [userType, setUserType] = useState('user'); // 'user' or 'shopOwner
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
  useBoolean(false);
  const [selectedOrder,setSelectedOrder]=useState<any>();
  const titleId = useId("title");
  const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  header: [
    theme.fonts.mediumPlus,
    {
      flex: '1 1 auto',
      // borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      // alignItems: 'center',
      justifyContent: "space-between",
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
    
  ],
  heading: {
    color: theme.palette.neutralPrimary,
    fontWeight: FontWeights.semibold,
    fontSize: 'inherit',
    margin: '0',
  },
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
});
  const handleUserTypeChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setUserType(event.target.value);
  };

  useEffect(() => {
    getAllOrders();
  }, [props.currComponent]);
  const getAllOrders = async () => {
    try {
      // const response = await axios.get(`${process.env.REACT_APP_API_URL}/allorders`, {
      //   headers: {
      //     "x-token": localStorage.getItem("token"),
      //   },
      // });
      let mailTemp=userType === 'user'?"userorders":"shoporders";
      // console.log("{process.env.REACT_APP_API_URL",process.env.REACT_APP_API_URL)
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/${mailTemp}/${userMail}`, {
        headers: {
          "x-token": localStorage.getItem("token"),
        },
      });
      const allOrders: any[] = [];
      console.log("all orders,", response.data);
      const ordersData = response.data;
      const monthlyOrderCounts = new Array(12).fill(0);
      const monthlyOrderPrices = new Array(12).fill(0);
      const weeklyOrderCount: number[] = new Array(7).fill(0); // An array to store daily user counts for the last 7 days
      const weeklyOrderPrice: number[] = new Array(7).fill(0);
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 6);
      const today = new Date();
      ordersData.forEach((item: any) => {
        const fileNamesString = item?.file?.join(", ");
        let temp = {
          orderId: item._id,
          shop: item.shop,
          document: fileNamesString,
          price: item.price,
          status: item.status,
          createdAt: item.createdAt,
          userMail: item.userMail,
          userName: item.userName,
          item:item,
        };
        allOrders.push(temp);
        const orderDate = new Date(item.createdAt);
        // if(item.status== "PickUp Completed")
        // {
        const monthIndex = orderDate.getMonth();
        monthlyOrderCounts[monthIndex]++;
        monthlyOrderPrices[monthIndex] += parseFloat(item.price); // Assuming "price" is a string representing a numerical value
        // }
        const userDate = new Date(item.createdAt);
        const isWithinLast7Days = userDate >= startDate && userDate <= today;

        // if ( isWithinLast7Days) {
        const daysAgo = Math.floor(
          (today.getTime() - userDate.getTime()) / (1000 * 60 * 60 * 24)
        );
        // Increment the daily user count for the corresponding day
        weeklyOrderCount[daysAgo]++;
        weeklyOrderPrice[daysAgo] += parseFloat(item.price);
        // }
      });
      monthlyOrdersCount = monthlyOrderCounts;
      monthlyOrdersPrice = monthlyOrderPrices;
      weeklyOrdersCount = weeklyOrderCount;
      weeklyOrdersPrice = weeklyOrderPrice;
      // setItems(allOrders.filter((order) => order.status === status));
      setItems(allOrders);
      allItems=allOrders
      setTotalPages(Math.ceil(allOrders.length / 10));
    } catch (error) {
      console.error("Error fetching all orders:", error);
      // Handle the error as needed
      return [];
    }
  };
  const labels = [];
  const startDate = new Date(); // Use the current date as the start date
  startDate.setDate(startDate.getDate() - 6); // Subtract 6 days to get the start date
  const endDate = new Date(); // Use the current date as the end date

  while (startDate <= endDate) {
    const dayOfMonth = startDate.getDate(); // Get the day of the month
    labels.push(dayOfMonth.toString()); // Push the day of the month as a string
    startDate.setDate(startDate.getDate() + 1); // Increment date by one day
  }

  const lineChartData = {
    labels,
    datasets: [
      {
        label: "Price",
        // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        data: weeklyOrdersPrice,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Orders ",
        // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        data: weeklyOrdersCount,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  const barChartLabels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const barChartData = {
    labels: barChartLabels, // Use the barChartLabels array for x-axis labels
    datasets: [
      {
        label: "Price",
        data: monthlyOrdersPrice, // Use your data for monthly shop owners
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Orders",
        data: monthlyOrdersCount, // Use your data for monthly users
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  const columns: IColumn[] = [
    // Define your columns here
    // Example:
    {
      key: "column1",
      name: "Order ID",
      fieldName: "orderId",
      minWidth: 150,
      maxWidth: 150,
      onRender: (item: any) => {
        return (
          <Link
            to={""}
            onClick={() => {
              setSelectedOrder(item);
              showModal();
            }}
            className="text-blue-700"
          >
            {item.orderId}
          </Link>
        );
      },
    },
    {
      key: "column3",
      name: "Documents",
      fieldName: "document",
      minWidth: 200,
      maxWidth: 200,
    },
    {
      key: "column2",
      name: "Shop",
      fieldName: "shop",
      minWidth: 120,
      maxWidth: 120,
    },
    {
      key: "column4",
      name: "Price",
      fieldName: "price",
      minWidth: 50,
      maxWidth: 50,
      onRender: (item: any) => {
        return <span>Rs.{item.price}</span>;
      },
    },
    {
      key: "column5",
      name: "Status",
      fieldName: "status",
      minWidth: 75,
      maxWidth: 75,
      // onRender: (item: any) => {
      //   if (item.status === "Requested") {
      //     return (
      //       <DefaultButton
      //         className="bg-sky-500 border rounded-md text-gray-100 border-green-300 hover:bg-sky-800 hover:text-gray-100"
      //         iconProps={{ iconName: "Add" }}
      //         title="Take Action"
      //         text="Accept"
      //         // disabled={kycStatus}
      //         // onClick={() => {
      //         //   if (!kycStatus) {
      //         //     toggleHideDialog();
      //         //   } else {
      //         //     updateOrderStatus(item?.orderId, "Payment",item?.userName,item?.userMail, item?.shop);
      //         //   }
      //         // }}
      //       />
      //     );
      //   } else if (item.status === "Processing") {
      //     return (
      //       <DefaultButton
      //         className="bg-green-300 border rounded-sm border-green-300"
      //         iconProps={{ iconName: "Add" }}
      //         title="Take Action"
      //         text="Completed"
      //         // onClick={() => updateOrderStatus(item?.orderId, "Completed",item?.userName,item?.userMail,item?.shop)}
      //       />
      //     );
      //   } else {
      //     return <span>{item.status}</span>;
      //   }
      // },
    },
  ];
  const [currentPage, setCurrentPage] = useState(1);
  // const [totalPages, setTotalPages] = useState(Math.ceil(items.length / 10));
  const currentPageNumber = Math.min(totalPages, currentPage);
  const previousPageNumber = Math.max(1, currentPage - 1);
  const nextPageNumber = Math.min(totalPages, currentPage + 2);
  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  const [filterText, setFilterText] = useState('');
  const onFilterChange = (event:any, text:any) => {
    if (text.trim() === '') {
      // If the text input is empty, reset the filter and show all profiles
      setFilterText('');
      setItems(allItems); // Replace 'data' with your original data array 
    } else {
      setFilterText(text);
      const filteredItems = items.filter((item: any) =>
        item.orderId.includes(text) ||
        item.price.includes(text) ||
        item.shop.toLowerCase().includes(text.toLowerCase()) ||
        item.status.toLowerCase().includes(text.toLowerCase()) ||
        item.shop.toLowerCase().includes(text.toLowerCase()) 
        // ||
        // item.userMail.toLowerCase().includes(text.toLowerCase())
      );
      setItems(filteredItems);
    }
  };
  return (
    <div className="flex gap-16 items-start justify-between ">
        <div className="">
          {/* {items?.length > 0 ? ( */}
            <div>
            <div className="flex gap-10">
          <label>
            <input
              type="radio"
              value="user"
              checked={userType === 'user'}
              onChange={handleUserTypeChange}
            />
            User
          </label>
          <label>
            <input
              type="radio"
              value="shopOwner"
              checked={userType === 'shopOwner'}
              onChange={handleUserTypeChange}
            />
            Shop Owner
          </label>
        </div>
            <div className="w-4/5">
                <TextField
                  label="Fetch orders based on user"
                  onChange={(event:any, text:any)=>setUserMail(text)}
                  value={userMail}
                  // className="w-100"
                />
                <button onClick={()=>getAllOrders()}>Fetch</button>
              </div>
              <div className="w-4/5">
                <TextField
                  label="Search User"
                  onChange={onFilterChange}
                  value={filterText}
                  // className="w-100"
                />
              </div>
              <DetailsList
                items={items.slice((currentPage - 1) * 10, currentPage * 10)}
                columns={columns}
                layoutMode={DetailsListLayoutMode.justified}
                selectionMode={SelectionMode.none}
              />
              <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
                <div className="flex flex-1 justify-between sm:hidden">
                  <a
                    href="#"
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  >
                    Previous
                  </a>
                  <a
                    href="#"
                    className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  >
                    Next
                  </a>
                </div>
                <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                  <div>
                    <p className="text-sm text-gray-700">
                      Showing{" "}
                      <span className="font-medium">{currentPageNumber}</span>{" "}
                      to{" "}
                      <span className="font-medium">
                        {pages[currentPageNumber - 1]}
                      </span>{" "}
                      of <span className="font-medium">{totalPages}</span>{" "}
                      results
                    </p>
                  </div>
                  <div>
                    <nav
                      className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                      aria-label="Pagination"
                    >
                      {/* Previous */}
                      <a
                        href="#"
                        className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                        onClick={() => handlePageChange(previousPageNumber)}
                      >
                        <span className="sr-only">Previous</span>
                        <ChevronLeftIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </a>

                      {/* Current Page */}
                      {totalPages > 1 && (
                        <>
                          {pages?.map((pageNumber: any) => (
                            <>
                              <a
                                key={pageNumber}
                                href="#"
                                className={`relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                                onClick={() => handlePageChange(pageNumber)}
                                // aria-current={
                                //   pageNumber === currentPageNumber ? "page" : null
                                // }
                              >
                                {pageNumber}
                              </a>
                            </>
                          ))}
                        </>
                      )}

                      {/* Other Pages */}
                      {totalPages > 10 && (
                        <>
                          {/* ... */}
                          <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
                            ...
                          </span>

                          {/* Next Page */}
                          <a
                            href="#"
                            className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                            onClick={() => handlePageChange(nextPageNumber)}
                          >
                            {nextPageNumber}
                          </a>
                        </>
                      )}
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          {/* // ) : (
          //   <div>No data</div>
          // )} */}
        </div>

      {/* <div className="w-3/4">
        <div className="border rounded-md bg-white hover:bg-slate-300 drop-shadow-sm hover:drop-shadow-lg"> */}
         <div  className="w-1/2">
          <LineChart data={lineChartData} />
        {/* </div>
        <div className="border rounded-md bg-white hover:bg-slate-300 drop-shadow-md hover:drop-shadow-lg"> */}
          <Barchart data={barChartData} />
        </div>
      {isModalOpen&&
        <Modal
        titleAriaId={titleId}
        isOpen={isModalOpen}
        onDismiss={hideModal}
        isModeless={true}
        containerClassName={contentStyles.container}
        dragOptions={isDraggable ? dragOptions : undefined}
        className="rounded-md"
      >
        <div className={contentStyles.header}>
          <h2 className={contentStyles.heading} id={titleId}>
          Order Details
          </h2>
          <XMarkIcon className="h-5 w-5 hover:bg-gray-300" aria-hidden="true"  onClick={hideModal}/>
        </div>
        <div className={contentStyles.body}>
          <div className="flex items-center gap-4">
        <Label>Order ID:</Label><Text> {selectedOrder?.orderId}</Text>
        </div>
        <div className="flex items-center gap-4">
        <Label>Date: </Label>
        <Text>{new Date(selectedOrder?.createdAt).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}</Text>
        </div>
        <div className="flex items-center gap-4">
        <Label>Time: </Label>
        <Text>{new Date(selectedOrder?.createdAt).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', second: '2-digit' })}</Text>
        </div>
        <div className="flex items-center gap-4">
        <Label>User:</Label><Text> {selectedOrder?.userName}</Text>
        </div>
        <div className="flex items-center gap-4">
        <Label>User Email: </Label><Text> {selectedOrder?.userMail}</Text>
        </div>
        <div className="flex items-center gap-4">
        <Label>User Phonenumber: </Label><Text>+91 {selectedOrder?.userPhoneNumber}</Text>
        </div>
        <div className="flex items-center gap-4">
        <Label>Shop:</Label><Text> {selectedOrder?.shop}</Text>
        </div>
        <div className="flex items-center gap-4">
        <Label>Status: </Label><Text> {selectedOrder?.status}</Text>
        </div>
        <hr/>
        <div className="flex items-center gap-4">
        <Label>File: </Label><Text> {selectedOrder?.document}</Text>
        </div>
        <div className="flex items-center gap-4">
        <Label>Number of Copies: </Label><Text> {selectedOrder?.numOfCopies}{" "}{selectedOrder?.numOfCopies==1?" print":( "1 print and remaining xerox's")}</Text>
        </div>
        <div className="flex items-center gap-4">
        <Label>Pages: </Label><Text> {selectedOrder?.item?.pages}</Text>
        </div>
        <div className="flex items-center gap-4">
        <Label>Print Type: </Label><Text> {selectedOrder?.item?.printType}</Text>
        </div>
        <div className="flex items-center gap-4">
        <Label>Xerox Price: </Label><Text> {selectedOrder?.item?.xeroxPrice}</Text>
        </div>
        <div className="flex items-center gap-4">
        <Label>Print Price: </Label><Text> {selectedOrder?.item?.printPrice}</Text>
        </div>
        <div className="flex items-center gap-4">
        <Label>Total Price: </Label><Text>Rs. {selectedOrder?.item?.price} ({selectedOrder?.item?.appCharges})</Text>
        </div>
        {/* {selectedOrder?.status!="PickUp Completed"&&
        <div className="flex justify-center gap-8 ">
        <div className="bg-blue-700 text-white p-2 rounded">
        <button onClick={()=>handleDownload(selectedOrder?.file[0],selectedOrder?.userId)}>Download</button>
        </div>
        <div className="bg-yellow-700 text-white p-2 rounded">
        <button onClick={()=>handlePrint(selectedOrder?.file[0],selectedOrder?.userId)}>Print</button>
        </div>
        </div>
        } */}
        </div>
      </Modal>
}
      {/* </div> */}
    </div>
  );
}

export default Orders;
