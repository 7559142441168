// src/redux/store.ts
import { configureStore } from '@reduxjs/toolkit';
import profileReducer from './slices/ProfileSlice';
import countReducer from './slices/CountSlice';
import kycStatusReducer from './slices/KycStatusSlice';

const store = configureStore({
  reducer: {
    profiles: profileReducer,
    counts: countReducer,
    kycStatus: kycStatusReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
