import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Monthly Data', // Remove the year from the text
    },
  },
};

const labels = ['Jan', 'Feb', 'Mar', 'Apl', 'May', 'June', 'July','Aug','Sep','Oct','Nov','Dec'];


export default function Barchart(props:any) {
    //  const data = {
    //   labels,
    //   datasets: [
    //     {
    //       label: 'price',
    //     //   data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
    //     data:props.monthlyOrderPrice,
    //       backgroundColor: 'rgba(255, 99, 132, 0.5)',
    //     },
    //     {
    //       label: 'orders',
    //     //   data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
    //     data: props.monthlyOrdersCount,
    //       backgroundColor: 'rgba(53, 162, 235, 0.5)',
    //     },
    //   ],
    // };


  return <Bar options={options} data={props.data} style={{ width:'500px',height:"500px"}}/>;
}
