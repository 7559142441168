// src/redux/slices/kycStatusSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface KycStatus {
  userId: string;
  kycStatus: string;
}

const kycStatusSlice = createSlice({
  name: 'kycStatus',
  initialState: [] as KycStatus[],
  reducers: {
    updateKycStatus: (state, action: PayloadAction<KycStatus>) => {
      const { userId, kycStatus } = action.payload;
      const userIndex = state.findIndex((user) => user.userId === userId);

      if (userIndex !== -1) {
        state[userIndex].kycStatus = kycStatus;
      } else {
        state.push(action.payload);
      }
    },
  },
});

export const { updateKycStatus } = kycStatusSlice.actions;
export default kycStatusSlice.reducer;
