import {
  ContextualMenu,
  DefaultButton,
  FontWeights,
  IDragOptions,
  IIconProps,
  IconButton,
  Text,
  Label,
  Link,
  Modal,
  getTheme,
  mergeStyleSets,
  TextField,
  PrimaryButton,
} from "@fluentui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { ChevronLeftIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useId, useBoolean } from "@fluentui/react-hooks";
import LineChart from "../charts/LineChart";
import Barchart from "../charts/BarChart";
import { useDispatch, useSelector } from 'react-redux';
import { setProfiles as setProfilesTemp } from '../redux/slices/ProfileSlice';
import { setCounts } from '../redux/slices/CountSlice';
import { updateKycStatus as updateKycStatusInStore } from '../redux/slices/KycStatusSlice';
import { RootState } from "../redux/store";
import GooglePayButton from '@google-pay/button-react';

const dragOptions: IDragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
  dragHandleSelector: ".ms-Modal-scrollableContent > div:first-child",
};
const cancelIcon: IIconProps = { iconName: "Cancel" };
let allShopOwners: any;
let allProfileValues:any;

let monthlyUsersCount: any = [];
let monthlyShopOwnersCount: any = [];
let dailyUsersCount: any = [];
let dailyShopOwnersCount: any = [];
let totalUsers = 0; 
let totalShopOwners = 0; 

function UserTable() {

  const dispatch = useDispatch();
  const profilesTemp = useSelector((state: RootState) => state.profiles);
  const counts = useSelector((state: RootState) => state.counts);
  const kycStatusTemp = useSelector((state: RootState) => state.kycStatus);

  const [show, setShow] = useState(null);
  const [profiles, setProfiles] = useState<any>([]);
  const [usertype, setUserType] = useState<any>("user");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(Math.ceil(profiles.length / 10));
  const currentPageNumber = Math.min(totalPages, currentPage);
  const previousPageNumber = Math.max(1, currentPage - 1);
  const nextPageNumber = Math.min(totalPages, currentPage + 2);
  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
  const [shopOwners, setShopOwners] = useState([]);
  const [isPanValid, setIsPanValid] = useState(false);
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [price, setPrice] = useState("");
  const [image, setImage] = useState<any>(null);
  const [ownerId, setOwnerId] = useState<any>();
  const [email, setEmail] = useState<any>();
  const [phoneNumber, setPhoneNumber] = useState<any>();
  const [upiID, setUpiID] = useState<any>();
  const [adharNumber, setAdharNumber] = useState<any>();
  const [panNumber,setPanNumber] = useState<any>();
  const [isValidUPIId, setIsValidUPIId] = useState(false);
  // const [upiId, setUpiId] = useState('');
  const [validationResult, setValidationResult] = useState("");
  const [isAadhaarValid, setIsAadhaarValid] = useState(false);
  const [shopOwner, setShopOwner] = useState();
  const [imageName, setImageName] = useState("");
  const [locationDetails, setLocationDetails] = useState<any>(null);
  const [kycStatus, setKycStatus] = useState<any>();
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);

  const [imageSrc, setImageSrc] = useState("");
  const [loading, setLoading] = useState(true);
 const [merchantId,setMerchantID] = useState("");
  // Use useId() to ensure that the IDs are unique on the page.
  // (It's also okay to use plain strings and manually ensure uniqueness.)
  const [merchantUPILink,setMerchantUPILink]=useState("");
  const [merchantStatus,setMerchantStatus] = useState("InActive");
  const [rPayAccountId,setRPayAccountID] = useState("");
  const titleId = useId("title");

  useEffect(() => {
    fetchShopOwners();
    fetchAllProfiles();
  }, [usertype]);
  const fetchAllProfiles = async () => {
    try {
      const response = await axios.get(
        usertype=="user"?`${process.env.REACT_APP_API_URL}/alluserprofiles`:`${process.env.REACT_APP_API_URL}/allshopprofiles`
        , {
        headers: {
          "x-token": localStorage.getItem("token"),
        },
      });

      if (response.status !== 200) {
        throw new Error("Failed to fetch profiles");
      }
      let alladdress:any={};
      
      allShopOwners.map((shp:any)=>{
        alladdress[shp.ownerId]=shp.address;
      })
      const allUsers = response.data?.map((item: any) => ({
        id: item._id,
        fullname: item.fullname,
        email: item.email,
        usertype: item.usertype,
        createdAt: item?.createdAt || new Date(),
        address:alladdress[item._id]||""
      }));

      setProfiles(
        allUsers.filter(
          (order: { usertype: any }) => order.usertype === usertype
        )
      );
      dispatch(setProfilesTemp(allUsers));
      setTotalPages(Math.ceil(allUsers.length / 10));
      allProfileValues=allUsers.filter(
        (order: { usertype: any }) => order.usertype === usertype
      );
      // const ordersData = response.data;
      const monthlyUsersCounts = new Array(12).fill(0); // An array to store monthly order counts (0 to 11, representing Jan to Dec)
     
      const monthlyShopOwnerCounts  = new Array(12).fill(0);// An array to store monthly order prices
     
      const today = new Date();
      response.data.forEach((item: any) => {
        const orderDate = new Date(item.createdAt);
        if(item.usertype == "user")
        {
          const monthIndex = orderDate.getMonth();
          monthlyUsersCounts[monthIndex]++;
        }
        if(item.usertype == "owner")
        {
          const monthIndex = orderDate.getMonth();
          monthlyShopOwnerCounts[monthIndex]++;
        }
      })
      setCurrentPage(1);
      monthlyShopOwnersCount=monthlyShopOwnerCounts;
      monthlyUsersCount=monthlyUsersCounts;
      const dailyUserCounts: number[] = new Array(7).fill(0); // An array to store daily user counts for the last 7 days
const dailyShopOwnerCounts: number[] = new Array(7).fill(0); // An array to store daily shop owner counts for the last 7 days

// Calculate the start date (7 days ago from today)
const startDate = new Date();
startDate.setDate(startDate.getDate() - 6); // Subtract 6 to get 7 days in total

allUsers.forEach((item: any) => {
  const userDate = new Date(item.createdAt);
  const isWithinLast7Days = (
    userDate >= startDate &&
    userDate <= today
  );

  if (item.usertype === 'user' && isWithinLast7Days) {
    const daysAgo = Math.floor((today.getTime() - userDate.getTime()) / (1000 * 60 * 60 * 24));
    // Increment the daily user count for the corresponding day
    dailyUserCounts[daysAgo]++;
  }

  if (item.usertype === 'owner' && isWithinLast7Days) {
    const daysAgo = Math.floor((today.getTime() - userDate.getTime()) / (1000 * 60 * 60 * 24));
    // Increment the daily shop owner count for the corresponding day
    dailyShopOwnerCounts[daysAgo]++;
  }
});

// Now, dailyUserCounts and dailyShopOwnerCounts hold daily counts for users and shop owners for the last 7 days.
dailyUsersCount = dailyUserCounts;
dailyShopOwnersCount = dailyShopOwnerCounts;
dailyUsersCount.reverse();
dailyShopOwnersCount.reverse();
    } catch (error) {
      console.error(error);
      // Handle the error here
    }
  };
  const deleteProfile = async (profileId: any) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/deleteprofile/${profileId}`, {
        headers: {
          "x-token": localStorage.getItem("token"),
        },
      });
      console.log("Profile deleted successfully");
      alert("Profile deleted successfully");
      fetchAllProfiles();
    } catch (error) {
      console.log(error);
      // Handle the error here
    }
  };

  const fetchShopOwners = async () => {
    try {
      const token = localStorage.getItem("token") || ""; // Use an empty string as a default
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/shopowners`,
        {
          headers: {
            "x-token": token,
          },
        }
      );
      const data = await response.json();
      setShopOwners(data);
      allShopOwners = data;
      // console.log("data1", data);
    } catch (error) {
      console.log("Error fetching shop owners:", error);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  const fetchShopOwner = async (ownerId: any) => {
    setOwnerId(ownerId);
    const token = localStorage.getItem("token") || ""; 
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/shopowner/${ownerId}`,
        {
          headers: {
            "x-token": token,
          },
        }
      );
      let temp = {
        shopName: response.data.shopName,
        location: response.data.location,
        prices: response.data.prices,
      };
      setName(response.data.shopName);
      setLocation(response.data.location);
      setPrice(response.data.prices);
      setShopOwner(response.data);
      setImageName(response.data.image);
      setLocationDetails(response.data.address);
      setPhoneNumber(response.data.phoneNumber);
      // setAdharNumber(response.data.adharNumber);
      setPanNumber(response.data.panNumber);
      setUpiID(response.data.upiID);
      setKycStatus(response.data.kycStatus);
      setMerchantID(response.data?.merchantId||"");
      setMerchantStatus(response.data?.merchantStatus||"");
      setRPayAccountID(response.data?.RPay_accountID||"");
      // console.log("res.data.address", response.data);
      showModal();
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    // Function to fetch the image
    const fetchImage = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/images/${imageName}`,
          {
            responseType: "blob",
          }
        );
        const src = URL.createObjectURL(response.data);
        setImageSrc(src);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching image:", error);
      }
    };

    // Call the fetchImage function when the component mounts
    fetchImage();

    // Clean up the URL object when the component unmounts
    return () => {
      if (imageSrc) {
        URL.revokeObjectURL(imageSrc);
      }
    };
  }, [imageName]);

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
      try {
        const formData = new FormData();
        // formData.append("shopName", name);
        formData.append("kycStatus", kycStatus);
        await axios.put(
          `${process.env.REACT_APP_API_URL}/shopowner/${ownerId}/update-kyc`,
          {
            kycStatus,
            merchantId,
            merchantStatus,
            rPayAccountId
          }
        );
        // Dispatch an action to update kycstatus in the store
        dispatch(
          updateKycStatusInStore({
            userId: ownerId,
            kycStatus: kycStatus,
          })
        );
        // Handle success
        alert("Shop owner details updated successfully");
        // console.log(formData,"formData")
        // fetchShopOwner();
      } catch (error) {
        alert("Error updating shop owner details:");
        // Handle error
      }
  };
  const handleKycStatusChange = (event:any, newValue:any) => {
    setKycStatus(newValue);
  };
  const handleMerchantChange = (event:any, newValue:any) => {
    setMerchantID(newValue);
  };
  const handleMerchantStatusChange = (event:any, newValue:any) => {
    setMerchantStatus(newValue);
  };
  const handleRPayChange = (event:any, newValue:any) => {
    setRPayAccountID(newValue);
  };
  const [filterText, setFilterText] = useState('');
  const onFilterChange = (event:any, text:any) => {
    if (text.trim() === '') {
      // If the text input is empty, reset the filter and show all profiles
      setFilterText('');
      setProfiles(allProfileValues); // Replace 'data' with your original data array
    } else {
      setFilterText(text);
      const filteredItems = profiles.filter((item: any) =>
        item.fullname.toLowerCase().includes(text.toLowerCase()) ||
        item.email.toLowerCase().includes(text.toLowerCase()) ||
        item.usertype.toLowerCase().includes(text.toLowerCase())||
        item?.address?.city?.toLowerCase().includes(text.toLowerCase())
      );
      setProfiles(filteredItems);
    }
  };

// Calculate the date range for the last one week (7 days)
const labels = [];
const startDate = new Date(); // Use the current date as the start date
startDate.setDate(startDate.getDate() - 6); // Subtract 6 days to get the start date
const endDate = new Date(); // Use the current date as the end date

while (startDate <= endDate) {
  const dayOfMonth = startDate.getDate(); // Get the day of the month
  labels.push(dayOfMonth.toString()); // Push the day of the month as a string
  startDate.setDate(startDate.getDate() + 1); // Increment date by one day
}

  const lineChartData = {
    labels,
    datasets: [
      {
        label: 'owners',
        // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        data:dailyShopOwnersCount,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'users ',
        // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        data:dailyUsersCount,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };
  const barChartLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const barChartData = {
  labels: barChartLabels, // Use the barChartLabels array for x-axis labels
  datasets: [
    {
      label: 'Owners',
      data: monthlyShopOwnersCount, // Use your data for monthly shop owners
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Users',
      data: monthlyUsersCount, // Use your data for monthly users
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

const handlePanVerify = () => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${process.env.REACT_APP_SUREPASS_TOKEN}`//Bearer 
  };
  // console.log("process.env.REACT_APP_SUREPASS_TOKEN",process.env.REACT_APP_SUREPASS_TOKEN )

  const requestData = {
    id_number: panNumber
  };

  axios.post('https://sandbox.surepass.io/api/v1/pan/pan', requestData, { headers })
    .then(response => {
      // setVerificationResult(response.data);
      setIsPanValid(true);
      setPanNumber(panNumber);
      // console.log("panNumber data:",response.data);
    })
    .catch(error => {
      if(error.response.data.message =='Invalid PAN')
      {
        alert('Invalid panNumber');
      }
      console.error('Error verifying Aadhaar:', error);
    });
};

  return (
    <div className="flex gap-12 h-full">
      <div className="w-3/4">
      <div className="flex gap">
        <button
          className={`mx-2 my-2 w-32 hover:bg-sky-700 transition duration-150 ease-in-out focus:outline-none rounded text-gray-800 border border-gray-300 px-6 py-2 text-xs
          ${usertype === "user" ? "bg-cyan-700 text-white" : "bg-white"}`}
          onClick={() => setUserType("user")}
        >
          {" "}
          Users
        </button>
        <button
          className={`mx-2 my-2 w-32 hover:bg-sky-700 transition duration-150 ease-in-out focus:outline-none rounded text-gray-800 border border-gray-300 px-6 py-2 text-xs
         ${usertype === "owner" ? "bg-blue-800 text-white" : "bg-white"}`}
          onClick={() => setUserType("owner")}
        >
          {" "}
          Shop Owners
        </button>
      </div>
    <div className="w-4/5">
    <TextField
        label="Search User"
        onChange={onFilterChange}
        value={filterText}
        // className="w-100"
      />
    </div>
      <div className="font-sans">
        <div className="w-full lg:w-5/6 w-auto">
          <div className="bg-white shadow-md rounded my-6">
            <table className="min-w-max w-full table-auto">
              <thead>
                <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                  <th className="py-3 px-6 text-left">User</th>
                  <th className="py-3 px-6 text-left">Email</th>
                  {/* <th className="py-3 px-6 text-center">Users</th> */}
                  <th className="py-3 px-6 text-center">User Type</th>
                  {usertype=="owner"&&
                  <th className="py-3 px-6 text-center">City</th>
                  }
                  <th className="py-3 px-6 text-center">Actions</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-sm font-light">
                {profiles &&
                  profiles
                    .slice((currentPage - 1) * 10, currentPage * 10)
                    .map((item: any, index: any) => (
                      <tr
                        key={index}
                        className="border-b border-gray-200 hover:bg-gray-100"
                      >
                        <td className="py-3 px-6 text-left whitespace-nowrap">
                          <div className="flex items-center">
                            {/* <div className="mr-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                                         width="24" height="24"
                                                         viewBox="0 0 48 48"
                                                         style={{fill:"#000000"}} >
                                                        <path fill="#80deea" d="M24,34C11.1,34,1,29.6,1,24c0-5.6,10.1-10,23-10c12.9,0,23,4.4,23,10C47,29.6,36.9,34,24,34z M24,16	c-12.6,0-21,4.1-21,8c0,3.9,8.4,8,21,8s21-4.1,21-8C45,20.1,36.6,16,24,16z"></path><path fill="#80deea" d="M15.1,44.6c-1,0-1.8-0.2-2.6-0.7C7.6,41.1,8.9,30.2,15.3,19l0,0c3-5.2,6.7-9.6,10.3-12.4c3.9-3,7.4-3.9,9.8-2.5	c2.5,1.4,3.4,4.9,2.8,9.8c-0.6,4.6-2.6,10-5.6,15.2c-3,5.2-6.7,9.6-10.3,12.4C19.7,43.5,17.2,44.6,15.1,44.6z M32.9,5.4	c-1.6,0-3.7,0.9-6,2.7c-3.4,2.7-6.9,6.9-9.8,11.9l0,0c-6.3,10.9-6.9,20.3-3.6,22.2c1.7,1,4.5,0.1,7.6-2.3c3.4-2.7,6.9-6.9,9.8-11.9	c2.9-5,4.8-10.1,5.4-14.4c0.5-4-0.1-6.8-1.8-7.8C34,5.6,33.5,5.4,32.9,5.4z"></path><path fill="#80deea" d="M33,44.6c-5,0-12.2-6.1-17.6-15.6C8.9,17.8,7.6,6.9,12.5,4.1l0,0C17.4,1.3,26.2,7.8,32.7,19	c3,5.2,5,10.6,5.6,15.2c0.7,4.9-0.3,8.3-2.8,9.8C34.7,44.4,33.9,44.6,33,44.6z M13.5,5.8c-3.3,1.9-2.7,11.3,3.6,22.2	c6.3,10.9,14.1,16.1,17.4,14.2c1.7-1,2.3-3.8,1.8-7.8c-0.6-4.3-2.5-9.4-5.4-14.4C24.6,9.1,16.8,3.9,13.5,5.8L13.5,5.8z"></path><circle cx="24" cy="24" r="4" fill="#80deea"></circle>
                                                    </svg>
                                                </div> */}
                            <Link
                              onClick={() => fetchShopOwner(item?.id)}
                              className="font-medium"
                            >
                              {item.fullname}
                            </Link>
                          </div>
                        </td>
                        <td className="py-3 px-6 text-left">
                          <div className="flex items-center">
                            <span>{item.email}</span>
                          </div>
                        </td>
                        <td className="py-3 px-6 text-center">
                          <span className="bg-purple-200 text-purple-600 py-1 px-3 rounded-full text-xs">
                            {item.usertype}
                          </span>
                        </td>
                        {usertype=="owner"&&
                        <td className="py-3 px-6 text-center">
                          <span className="bg-purple-200 text-purple-600 py-1 px-3 rounded-full text-xs">
                            {item?.address?.city||""}
                          </span>
                        </td>
                            }
                        <td className="py-3 px-6 text-center">
                          <div className="flex item-center justify-center">
                            {/* <div className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                                    </svg>
                                                </div> */}
                            {/* <div className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                                />
                              </svg>
                            </div> */}
                            <div
                              className="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
                              onClick={() => deleteProfile(item?.id)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                              </svg>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
              </tbody>
              {/* <ul className="pagination flex">
                <li className="page-item">
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePageChange(1)}
                  >
                    1
                  </a>
                </li>
                <li className="page-item">
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePageChange(2)}
                  >
                    2
                  </a>
                </li>
                <li className="page-item">
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePageChange(3)}
                  >
                    3
                  </a>
                </li>
              </ul> */}
            </table>
            <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
              <div className="flex flex-1 justify-between sm:hidden">
                <a
                  href="#"
                  className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Previous
                </a>
                <a
                  href="#"
                  className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Next
                </a>
              </div>
              <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm text-gray-700">
                    Showing{" "}
                    <span className="font-medium">{currentPageNumber}</span> to{" "}
                    <span className="font-medium">
                      {pages[currentPageNumber - 1]}
                    </span>{" "}
                    of <span className="font-medium">{totalPages}</span> results
                  </p>
                </div>
                <div>
                  <nav
                    className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                    aria-label="Pagination"
                  >
                    {/* Previous */}
                    <a
                      href="#"
                      className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                      onClick={() => handlePageChange(previousPageNumber)}
                    >
                      <span className="sr-only">Previous</span>
                      <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </a>

                    {/* Current Page */}
                    {totalPages > 1 && (
                      <>
                        {pages?.map((pageNumber: any) => (
                          <>
                            <a
                              key={pageNumber}
                              href="#"
                              className={`relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                              onClick={() => handlePageChange(pageNumber)}
                              // aria-current={
                              //   pageNumber === currentPageNumber ? "page" : null
                              // }
                            >
                              {pageNumber}
                            </a>
                          </>
                        ))}
                      </>
                    )}

                    {/* Other Pages */}
                    {totalPages > 10 && (
                      <>
                        {/* ... */}
                        <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">
                          ...
                        </span>

                        {/* Next Page */}
                        <a
                          href="#"
                          className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                          onClick={() => handlePageChange(nextPageNumber)}
                        >
                          {nextPageNumber}
                        </a>
                      </>
                    )}
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div  className="w-1/2">
          <LineChart data={lineChartData}/>
          <Barchart data={barChartData}/>
        </div>
      <Modal
        titleAriaId={titleId}
        isOpen={isModalOpen}
        onDismiss={hideModal}
        isModeless={true}
        containerClassName={contentStyles.container}
        dragOptions={isDraggable ? dragOptions : undefined}
        className="rounded-md"
      >
        <div className="m-4">
          <div className="flex items-center justify-between">
          <Label className="text-xl">
          Shop Details 
            </Label>
            <XMarkIcon
              className="h-8 w-8 text-gray-600 hover:bg-gray-200"
              onClick={() => hideModal()}
            ></XMarkIcon>
          </div>
          <div className="flex mt-2 items-center justify-center">
            {loading ? (
              <p>Loading image...</p>
            ) : (
              <img src={imageSrc} alt="Image" height={200} width={400} className="rounded-md mt-14 ml-14 mr-14" />
            )}
          </div>
          <div>
            <div className="flex items-center justify-center">
              <Label className="text-xl">{name}</Label>
            </div>
            <div className="flex items-center justify-between">
              <Label>Shop Name:</Label>
              <Text>{name}</Text>
            </div>
            <div className="flex items-center justify-between">
              <Label>Prices:</Label>
              <Text>{price}</Text>
            </div>
            {/* <div className="flex">
      <Label>address</Label>
      <Text>{locationDetails}</Text>
      </div> */}
            <div className="flex items-center justify-between">
              <Label>Phone Number:</Label>
              <Text>{phoneNumber}</Text>
            </div>
            <div className="flex items-center justify-between">
              <Label>PAN Number:</Label>
              <Text>{panNumber}</Text>
              <div className="justify-end">
      <PrimaryButton disabled={isPanValid} className={isPanValid ? 'mt-2 bg-green-500 text-white rounded justify-self-end' : 'mt-2 rounded border-purple-600 bg-purple-600'} href="#"  onClick={handlePanVerify}>
      {isPanValid ? "Verified": "Validate"}
      </PrimaryButton>
      </div>
            </div>
            <div className="flex items-center justify-between">
              <Label>Upi ID:</Label>
              <Text>{upiID}</Text>
            </div>
            <div className="flex items-center justify-between">
              <Label>Kyc Status:</Label>
              <TextField
              type="text"
              value={kycStatus}
              onChange={handleKycStatusChange}
              ></TextField>
            </div>
            <div className="flex items-center justify-between">
              <Label>RPay account Id:</Label>
              <TextField
              type="text"
              value={rPayAccountId}
              onChange={handleRPayChange}
              ></TextField>
            </div>
            <div className="flex items-center justify-between">
              <Label>Merchant Id:</Label>
              <TextField
              type="text"
              value={merchantId}
              onChange={handleMerchantChange}
              ></TextField>
            </div>
            <div className="flex items-center justify-between">
              <Label>Merchant status:</Label>
              <TextField
              type="text"
              value={merchantStatus}
              onChange={handleMerchantStatusChange}
              ></TextField>
            </div>
            <div className="flex items-center justify-between">
              <Label>Merchant verify</Label>
              <button
              // type="text"
              // value={merchantId}
              className={`mx-2 my-2 w-38 bg-blue-700 text-white hover:bg-blue-300 transition duration-150 ease-in-out focus:outline-none rounded text-gray-800 border border-gray-300 px-6 py-2 text-xs
              `}
              onClick={() => {
               
            
                const upiUrl = `upi://pay?pa=${merchantId}&pn=${name}&tr=${"1234"}&tn=${"test"}&am=${"1"}&cu=INR`;
            
                // Redirect to the UPI URL
                setMerchantUPILink(upiUrl)
                // window.location.href = upiUrl;
              }}
              >generate link</button>
            </div>
            <div>
              <a href={merchantUPILink}>{name}:{merchantUPILink}</a>
            </div>
            {/* {kycStatus == "Pending" && ( */}
              <div className="flex items-center justify-center">
                <button
                  className={`mx-2 my-2 w-38 bg-blue-700 text-white hover:bg-blue-300 transition duration-150 ease-in-out focus:outline-none rounded text-gray-800 border border-gray-300 px-6 py-2 text-xs
         `}
                    onClick={ handleFormSubmit}
                >
                  {" "}
                  Update Kyc Status
                </button>
              </div>
            {/* )} */}
          </div>
        </div>
      </Modal>
    </div>
  );
}
const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },

  heading: {
    color: theme.palette.neutralPrimary,
    fontWeight: FontWeights.semibold,
    fontSize: 'inherit',
    margin: '0',
  },
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
});
const toggleStyles = { root: { marginBottom: '20px' } };
const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: "auto",
    marginTop: "4px",
    marginRight: "2px",
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

export default UserTable;
