import { Fragment, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import UserTable from './UserTable'
// import logo from '../assets/logo1.png';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { ContextualMenu, IDragOptions, Label, Modal, TextField ,Text} from '@fluentui/react';
import { useBoolean , useId} from '@fluentui/react-hooks';
import Orders from './Orders';
const user = {
  name: 'Tom Cook',
  email: 'tom@example.com',
  imageUrl:
    'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}
const navigation = [
  { name: 'Dashboard', href: '#', current: true },
  { name: 'Orders', href: '#', current: false },
  // { name: 'Projects', href: '#', current: false },
  // { name: 'Calendar', href: '#', current: false },
  // { name: 'Reports', href: '#', current: false },
]
const userNavigation = [
  { name: 'Your Profile', href: '#' },
  { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' },
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}
const dragOptions: IDragOptions = {
  moveMenuItemText: "Move",
  closeMenuItemText: "Close",
  menu: ContextualMenu,
  dragHandleSelector: ".ms-Modal-scrollableContent > div:first-child",
};
export default function DashBoard() {
  const navigate = useNavigate();
  const titleId = useId("title");
  const [currComponent,setCurrComponent] = useState("Dashboard");
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] =
    useBoolean(false);
  const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);

  const generateRandomToken = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let token = '';
  
    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      token += characters.charAt(randomIndex);
    }
  
    return token;
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    // Perform logout logic

    // Generate random token
    const token = generateRandomToken();

    // Redirect to login page with token
    // navigate(`/Login?token=${token}`);
    navigate(`/`);
    // Add this code after logout
    // sessionStorage.removeItem('isLoggedIn');
  };

  const handleAction = (action:any)=>{
    if(action == "Sign out"){
      handleLogout();
    }
    if(action == "Your Profile"){
      getAdminData();
    }
    if(action == "Dashboard")
    {
      setCurrComponent("Dashboard");
    }
    if(action == "Orders")
    {
      setCurrComponent("Orders");
    }
  }
  const [adminId,setAdminId] = useState("");
  const [adminName,setAdminName] = useState("");
  const [adminEmail,setAdminEmail] = useState("");
  const [UPIid,setUPIid] = useState("");
  const getAdminData = () =>{
    axios.get(`${process.env.REACT_APP_API_URL}/myprofile`, {
        headers: {
          "x-token": localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setAdminId(res.data?._id);
        setAdminEmail(res.data?.email)
        setAdminName(res.data?.fullname);
        setUPIid(res.data?.UPIid)
        // console.log("admin data", res.data);
        showModal();
      })
      .catch((error) => {
        console.log(error);
      });
  }

 
  const handleUpiIdchange = (event:any, newValue:any) => {
    setUPIid(newValue);
  };

  const updateUPIID = async () => {
    try {
      // Make a PUT request to update the UPI ID for the authenticated user
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/admin/update-upiid`, { UPIid ,signupMethod: "regular",}, {
        headers: {
          "x-token": localStorage.getItem("token"), // Pass your authentication token here
        },
      });
      alert(response.data.message);
      hideModal();
    } catch (error) {
      console.error('Error updating UPI ID:', error);
      alert('Failed to update UPI ID');
    }
  };
  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
      <div className="min-h-full">
        <Disclosure as="nav" className="bg-gray-800">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      {/* <img
                        className="h-16 w-16"
                        src={logo}
                        alt="Your Company"
                      /> */}
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            onClick={()=>handleAction(item.name)}
                            className={classNames(
                              item?.name==currComponent
                                ? 'bg-gray-900 text-white'
                                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                              'rounded-md px-3 py-2 text-sm font-medium'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      <button
                        type="button"
                        className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      >
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button>

                      {/* Profile dropdown */}
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <span className="absolute -inset-1.5" />
                            <span className="sr-only">Open user menu</span>
                            <img className="h-8 w-8 rounded-full" src={user.imageUrl} alt="" />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <a
                                    href={item.href}
                                    className={classNames(
                                      active ? 'bg-gray-100' : '',
                                      'block px-4 py-2 text-sm text-gray-700'
                                    )}
                                    onClick={()=>handleAction(item.name)}
                                  >
                                    {item.name}
                                  </a>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'block rounded-md px-3 py-2 text-base font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                      onClick={()=>handleAction(item.name)}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
                <div className="border-t border-gray-700 pb-3 pt-4">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <img className="h-10 w-10 rounded-full" src={user.imageUrl} alt="" />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium leading-none text-white">{user.name}</div>
                      <div className="text-sm font-medium leading-none text-gray-400">{user.email}</div>
                    </div>
                    <button
                      type="button"
                      className="relative ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                    >
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-3 space-y-1 px-2">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {/* <header className="bg-white shadow">
          <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">Dashboard</h1>
          </div>
        </header> */}
        <main>
          <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 ">
          {currComponent == "Dashboard" && <UserTable />}  
          {currComponent == "Orders" && <Orders currComponent={currComponent}/>}  
            {/* Your content */}</div>
            <Modal
        titleAriaId={titleId}
        isOpen={isModalOpen}
        onDismiss={hideModal}
        isModeless={true}
        // containerClassName={contentStyles.container}
        dragOptions={isDraggable ? dragOptions : undefined}
        // className="rounded-md"
      >
        <div className="m-4">
          <div className="flex items-center justify-between">
          <Label className="text-xl">
          Admin Details 
            </Label>
            <XMarkIcon
              className="h-8 w-8 text-gray-600 hover:bg-gray-200"
              onClick={() => hideModal()}
            ></XMarkIcon>
          </div>
          <div>
          
            <div className="flex items-center justify-between">
              <Label>Admin Name:</Label>
              <Text>{adminName}</Text>
            </div>
            <div className="flex items-center justify-between">
              <Label>Mail:</Label>
              <Text>{adminEmail}</Text>
            </div>
           
            <div className="flex items-center justify-between">
              <Label>Merchant UPI id:</Label>
              <TextField
              type="text"
              value={UPIid}
              onChange={handleUpiIdchange}
              ></TextField>
            </div>
            {/* {kycStatus == "Pending" && ( */}
              <div className="flex items-center justify-center">
                <button
                  className={`mx-2 my-2 w-38 bg-blue-700 text-white hover:bg-blue-300 transition duration-150 ease-in-out focus:outline-none rounded text-gray-800 border border-gray-300 px-6 py-2 text-xs
         `}
                    onClick={ updateUPIID}
                >
                  {" "}
                  Update UPIid 
                </button>
              </div>
            {/* )} */}
          </div>
        </div>
      </Modal>
        </main>
      </div>
    </>
  )
}
