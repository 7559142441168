import { FontIcon, Icon, Label, mergeStyles } from "@fluentui/react";
import { FaArrowLeft } from "react-icons/fa";
import {FcGoogle} from  "react-icons/fc";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { GoogleLogin } from '@react-oauth/google';
// import { googleLogout, useGoogleLogin } from '@react-oauth/google';
// import RegisterModal from "../LandingPage/Modal/RegisterModal";
 const Login = () => {
 


const [ user, setUser ] = useState<any>([]);
    const [ profile, setProfile ] = useState<any>([]);

    const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [auth, setAuth] = React.useState(false);
  const [email, setEmail] = React.useState<string>();
  const [password, setPassword] = React.useState<string>();
  const [userType, setUserType] = React.useState<string>();
  const [otpVerify,setOtpVerify] = React.useState(false);
  const [data, setData] = React.useState<any>({
    email: "",
    password: "",
  });

  const changeHandler = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const submitHandler = (e: any) => {
    e.preventDefault();
    // console.log("data", data);
    axios.post(`${process.env.REACT_APP_API_URL}/login`, data).then((res) => {
      localStorage.setItem("token", res.data.token);
      // console.log(res.data, "res.data");
      setUserType(res.data.usertype);
      setAuth(true);
    });
  };
  if (localStorage.getItem("token")) {
    if (userType == "user") {
      navigate("/userhome");
      // alert("use mobile app");
    } else if(userType == "owner" ) {
      navigate("/ownerhome");
    }
    else if(userType == "regular" ){
      navigate("/phoneVerification");
      // setOtpVerify(true);
    }

    // sessionStorage.setItem('isLoggedIn', 'true');

    // history.replaceState(null, '', '/UserHome');
  }

  
  // React.useEffect(() => {
  //   if (auth) {
  //     navigate('/UserHome');
  //   }
  // }, []);
//   const login = useGoogleLogin({
//     onSuccess: (codeResponse:any) => setUser(codeResponse),
//     onError: (error) => console.log('Login Failed:', error)
// });
  


const signInWithGoogle=(temp: { email: any; })=>{
  axios.post(`${process.env.REACT_APP_API_URL}/loginwithgoogle`, temp).then((res) => {
  localStorage.setItem("token", res.data.token);
  // console.log(res.data, "res.data1");
  setUserType(res.data.usertype);
  setAuth(true);
});
}

useEffect(
    () => {
        if (user != '' && user != undefined) {
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {
                    setProfile(res.data);
                    localStorage.setItem("userInfo", JSON.stringify(res.data));
                    let temp={
                     email: res.data.email
                    }
                    signInWithGoogle(temp)
                })
                .catch((err) =>{
                 alert("User not registered")
                 setProfile(null);
                }
                 );
        }
    },
    [ user ]
);

// log out function to log the user out of google and set the profile array to null
// const logOut = () => {
//     googleLogout();
//     setProfile(null);
// };
// console.log("res.data.token",profile)

//console.log("userType",userType!=undefined?userType:"")
  return (
    <div
      id="login"
      className="relative flex flex-col justify-center min-h-screen overflow-hidden"
    >
      <div className="w-full p-6 m-auto lg:max-w-xl">
      <div className="items-center">
             <div className="flex items-center justify-center w-full mb-8">
              <Label className="text-xl">Welcome to Printout</Label>
             </div>
        </div>
        <form className="mt-6" onSubmit={submitHandler}>
          <div className="mb-2">
            <label
              htmlFor="email"
              className="block text-sm font-semibold text-gray-800"
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              onChange={changeHandler}
              className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div className="mb-2">
            <label
              htmlFor="password"
              className="block text-sm font-semibold text-gray-800"
            >
              Password
            </label>
            <input
              type="password"
              name="password"
              onChange={changeHandler}
              className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <a href="#" className="text-xs text-purple-600 hover:underline">
            Forget Password?
          </a>
          <div className="mt-6">
            <button
              type="submit"
              className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-purple-700 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Login;
