// src/redux/slices/countSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CountState {
  monthlyUsersCount: number[];
  monthlyShopOwnersCount: number[];
  dailyUsersCount: number[];
  dailyShopOwnersCount: number[];
}

const countSlice = createSlice({
  name: 'counts',
  initialState: {
    monthlyUsersCount: [],
    monthlyShopOwnersCount: [],
    dailyUsersCount: [],
    dailyShopOwnersCount: [],
  } as CountState,
  reducers: {
    setCounts: (state, action: PayloadAction<CountState>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setCounts } = countSlice.actions;
export default countSlice.reducer;
