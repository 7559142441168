// src/redux/slices/profileSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Profile {
  id: string;
  fullname: string;
  email: string;
  usertype: string;
  createdAt: Date;
}

const profileSlice = createSlice({
  name: 'profiles',
  initialState: [] as Profile[],
  reducers: {
    setProfiles: (state, action: PayloadAction<Profile[]>) => {
      return action.payload;
    },
  },
});

export const { setProfiles } = profileSlice.actions;
export default profileSlice.reducer;
